import React from "react";
import { image3 } from "../../assets/images";
import "./style.css";

export default function Rule() {
  return (
    <div className="order-guide">
      <div className="containers">
        <h4 className="containers-title">
          Quy trình đặt hàng <br /> trên ứng dụng Vemedim Dealer App
        </h4>
        <p className="text-justify">
          Bạn có thể đặt hàng trực tiếp trên ứng dụng Vemedim dành cho các khách
          hàng theo các bước dưới đây:
        </p>
        <div>
          <h6>Bước 1: Tìm sản phẩm cần mua</h6>
          <p className="text-justify">
            Chọn tính năng Đặt hàng trên ứng dụng và thao tác
            <br />
            <span className="d-block ps-3">
              1. Chọn biểu tượng tìm kiếm sản phẩm <br />
              2. Chọn sản phẩm muốn đặt hàng và xem thông tin chi tiết sản phẩm
              <br />
              3. Chọn số lượng và quy cách sản phẩm cần đặt <br />
            </span>
          </p>
        </div>
        <div className="text-justify">
          <h6>Bước 2: Giỏ hàng</h6>

          <p className="text-justify">
            Bạn có thể chính sửa/ thêm/ xóa sản phẩm cho đơn hàng. <br />
            Sau đó, nhấn chọn “Mua ngay” nếu bạn muốn mua ngay sản phẩm đã chọn.
            <br />
            Nhấn chọn “Thêm vào giỏ hàng” nếu bạn muốn mua nhiều sản phẩm. Tiếp
            tục thao tác giống Bước 1.
          </p>
          <p className="text-center">
            <img src={image3} style={{ width: "80%" }} />
          </p>
        </div>
        <div className="text-justify">
          <h6>Bước 3: Đặt hàng</h6>
          <p>
            Nhấn chọn “Mua ngay” và bấm nút “Xác nhận” để đặt hàng thành công
            hoặc bấm nút “Quay lại” để quay về Bước 2.
          </p>
        </div>
      </div>
    </div>
  );
}
