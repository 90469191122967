import React, { Fragment } from "react";
import { Collapse } from "antd";
import "../style.css";

const { Panel } = Collapse;
const listKeys = ["1"];
export default function TermContent({ open }) {
  return (
    <Fragment>
      {open && <p className="title-term">Điều khoản điều lệ</p>}
      <Collapse
        bordered={false}
        collapsible={open ? "disabled" : true}
        defaultActiveKey={open ? listKeys : []}
        expandIconPosition="right"
        className="content"
      >
        <Panel
          showArrow={!open}
          header={<span className="panel-title">1. THÔNG TIN ĐIỀU LỆ</span>}
          key="1"
        >
          <p>TEXT</p>
        </Panel>
      </Collapse>
    </Fragment>
  );
}
